@import 'variables.scss';

html, body {
    height: 100%;
    margin: 0;
}
html * {
    max-height: 999999px;
}
body {
    min-width: 320px;
    font-size: $fs-text;
    line-height: $lh-text;
    font-family: $text-font;
    color: $light-color;
    text-shadow: 0 0 10px $blue-color;
    background: $dark-color;
    margin: 0;
    -webkit-text-size-adjust: 100%;
}


/*	   general reset   	*/
* {
    scroll-behavior: smooth;
}
form, fieldset {
    margin: 0;
    padding: 0;
    border-style: none;
}
img {
    border-style: none;
    vertical-align: top;
}
ul li {
    vertical-align: top;
}
input, select, textarea {
    vertical-align: middle;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a {
    color: $light-color;
    text-decoration: none;
    transition: color .1s ease-in-out;

    &:hover {
        color: $blue-color;
        text-decoration: none;
        outline: 0 none;
    }
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
figure {
    margin: 0;
}
button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

#wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    min-height: 100%;
}
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}
p {
    margin: 0 0 20px;
}
h1,
h2 {
    display: block;
    font-size: $fs-title;
    font-weight: normal;
    margin: 0 0 30px;
}
h1 {
    border-bottom: 1px solid rgba($light-color, 0.5);
    padding: 0 0 20px;
    margin: 0 0 20px;
}
strong,
b {
    font-weight: 700;
}
ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
#main {
    width: 100%;
    min-height: calc(var(--vh, 1vh) * 100);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 30px 0;
    position: relative;
    z-index: 1;
    box-sizing: border-box;

    &:before {
        content: '';
        background-color: $dark-color;
        opacity: 0.8;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }
}
.main-box {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    column-gap: 20px;
    row-gap: 50px;

    .box {
        width: calc((100% - 60px)/4);
    }
}
ol {
    list-style: none;
    counter-reset: item;
    padding: 0;
    margin: 0;

    &.two-col {
        column-count: 2;
    }
    &.three-col {
        column-count: 3;
    }
    li {
        overflow: hidden;
        margin: 0 0 20px;

        &:last-of-type {
            margin: 0;
        }
    }
    a {
        display: inline-flex;
        align-items: center;
        column-gap: 10px;
        color: $light-color;

        &:before {
            content: counters(item, "", decimal) "";
            counter-increment: item;
            width: 24px;
            min-width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: $fs-text-mobile;
            border: 1px solid $light-color;
            border-radius: 50%;
            box-sizing: border-box;
        }
    }
}
hr {
    height: 1px;
    background-color: $light-color;
    border: none;
    opacity: 0.5;
    margin: 0 0 20px;
}