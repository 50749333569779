@import 'variables.scss';


@media only screen and (max-width: 1023px) {
    body {
        font-size: $fs-text-mobile;
    }
    h1,
    h2 {
        font-size: 22px;
        margin: 0 0 20px;
    }
    ol li,
    hr {
        margin: 0 0 10px;
    }
    .main-box .box {
        width: calc((100% - 40px)/3);
    }
}

@media only screen and (max-width: 719px) {
    .main-box .box {
        width: 100%;
    }
    ol {

        &.two-col,
        &.three-col {
            column-count: 1;
        }
    }
}