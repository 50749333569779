/*	   typography   	*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

$text-font: 'Roboto', sans-serif;
$fs-text: 16px;
$fs-text-mobile: 14px;
$lh-text: 1.2;
$fs-title: 26px;
/*	   end  typography   	*/


/*	   colors   	*/
$light-color: #f7f7f7;
$dark-color: #111;
$blue-color: blue;
/*	   end  colors   	*/